import React from "react";
import {Box, Text,Center , Image ,  Modal,
  ModalOverlay,
  ModalContent,

  ModalBody,
  ModalCloseButton,useDisclosure, Button} from "@chakra-ui/react";
import bg from './assets/background.webp'
import wallets from './assets/wallets.webp'
import guest from './assets/guest.webp'
import logo from './assets/logo.png';
import windows from './assets/windows.png';
import mac from './assets/mac.png';
import ClockLoader from "react-spinners/ClockLoader";
import {HiOutlineDownload} from "react-icons/hi"

function App() {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen : open, onOpen : whenOpen, onClose : whenClose } = useDisclosure()
  const [selected,setSelected] = React.useState(0)

  const data = [{
    key : "Os",
    value : "Windows 10 x64"
  },
  {
    key : "Processor",
    value : "Intel(R) Core(TM) i3-2120 or AMD equivalent"
  },
  {
    key : "Memory",
    value : "6 GB RAM"
  },
  {
    key : "Graphics",
    value : "GeForce GTX 960 or AMD equivalent, 4 GB VRAM"
  },
  {
    key : "DirectX",
    value : "Version 11"
  },
  {
    key : "Storage",
    value : "5 GB available space"
  },
  {
    key : "Sound Card",
    value : "DirectX compatible sound card"
  }
  
]

function download(url) {
  const a = document.createElement("a");
  a.href = url;

  const clickEvnt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvnt);
  a.remove();
}

  return (
    <>
      <Box bgImage={`linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.2)) ,url(${bg})`} bgSize="cover" height="100vh" width="100vw" fontFamily="Archivo,sans-serif">
        <Box width="100%" height="100%" pt={16}>
          <Center width="100%" height="auto"> 
              <Image src={logo} width="13rem" alt="logo" />

          </Center>
            <Center width="100%" height="auto" pt={3}>
                <Text as="h1" fontSize={["1.1rem","1.2rem","1.5rem","1.5rem"]} color="white" >Sign in or Create an Account</Text>
              </Center>
            <Center width="100%" height="auto" pt={10} >
                <Box width={["50%","40%","30%","30%", "20%"]} height="auto" bg="#ECECEE" mr={10} borderRadius="20px"> 
                  <Box width="100%" height="100%" cursor="pointer" _hover={{
                    border: '6px solid #FFC831',
                    borderRadius: '20px',
                    boxShadow: '2px 2px #FEE235, -1em 0 .4em #0F0D19;'

                  }} onClick={() => {setSelected(0);onOpen();}}>
                    <Center width="100%" pt={10} height="auto" textAlign="center">
                        <Text color="black" as="h1" fontSize={["1.0rem","1.1rem","1.3rem","1.3rem"]} fontWeight="550">Join using your wallet</Text>
                        
                    </Center>
                      <Center width="100%"  m={0} mt={-6}>
                          <Image src={wallets}  alt="logo" width="100%"/>
                          
                      </Center>
                      <Center width="100%" textAlign="center" mt={-3} pb={10}>
                      <Text color="black" as="h1" fontSize={["0.7rem","0.7rem","0.8rem","0.8rem"]} fontWeight="10" width="80%">Connect your account to fully enjoy MetaCratch!</Text>
                          
                      </Center>
                  </Box>
                </Box>
                <Box width={["50%","40%","30%","30%", "20%"]}  height="auto" bg="#ECECEE"  borderRadius="20px" > 
                  <Box width="100%" height="100%" cursor="pointer" _hover={{
                    border: '6px solid #FFC831',
                    borderRadius: '20px',
                    boxShadow: '2px 2px #FEE235, -1em 0 .4em #0F0D19;'
                      
                  }} onClick={() => {setSelected(0);onOpen();}}>
                    <Center width="100%" pt={10} height="auto" textAlign="center">
                        <Text color="black" as="h1" fontSize={["1.0rem","1.1rem","1.3rem","1.3rem"]} fontWeight="550">Join as a guest</Text>
                        
                    </Center>
                      <Center width="100%"  m={0} mt={-6}>
                          <Image src={guest}  alt="logo" width="100%"/>
                          
                      </Center>
                      <Center width="100%" textAlign="center" mt={-3} pb={10}>
                      <Text color="black" as="h1" fontSize={["0.7rem","0.7rem","0.8rem","0.8rem"]} fontWeight="10" width="80%">Join as an anonymous guest to get a taste of MetaCratch</Text>
                          
                      </Center>
                  </Box>
                </Box>
            </Center>
            <Center width="100%" pt={8}>
                  <Box width={["50%","40%","30%","30%", "20%"]} mr={10}>
                    <Center w="100%">
                      <Image cursor="pointer" src={windows} alt="windows" borderRadius="5px" w="55%" onClick={whenOpen} />
                    </Center>
                    
                  </Box>
                  <Box width={["50%","40%","30%","30%", "20%"]}>
                    <Center w="100%">
                      <Image cursor="pointer" src={mac} alt="mac" borderRadius="5px" w="55%" onClick={() => {setSelected(1);onOpen();}} />
                    </Center>
                  </Box>
            </Center>
        </Box>
        
        <Modal  isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton _focus={{border : "1px solid grey"}} border="1px solid grey" />
          <ModalBody>
            <Center width="100%" pt={10}>
              <Text as="h1" fontSize="1.5rem" fontWeight="bold" >Coming Soon on {selected === 0 ? "the Browser" : "Mac Os"}</Text>
             
            </Center> 
            <Center width="100%" pt={10} pb={10}>
                <ClockLoader loading={true} size={150} color={selected === 0 ? "#FFC831" : "grey"} />
             </Center>   
         </ModalBody>
        </ModalContent>
      </Modal>


      <Modal size="lg" isCentered isOpen={open} onClose={whenClose} motionPreset='slideInBottom'>
        <ModalOverlay />
        <ModalContent bg="#1F2326" borderRadius="5px">
          <ModalCloseButton color="white" _focus={{border : "1px solid grey"}} border="1px solid grey" />
          <ModalBody bg="#1F2326" borderRadius="5px">
            <Center width="100%" pt={10}>
              <Text as="h1" fontSize="1.5rem" fontWeight="bold"color="white" >SYSTEM REQUIREMENTS</Text>
              
            </Center> 
            <Center pt={10} pb={10}>
                <Box>
                  {data.map(({key,value}) => (
                    <Box display="flex" pb={4} textAlign='center' alignItems="center"> 
                      <Text fontSize={["1rem","1rem","1.2rem","1.2rem"]} color="#e8ecef" fontWeight="bold" textAlign="center">
                          {key}  : 
                      </Text>

                      <Text fontSize={["0.8rem","0.8rem","1rem","1rem"]} color="#e8ecef" pl={3} textAlign="center">
                      {value}
                      </Text>
                  </Box>
                  ))}
                  <Center>
                  <Button onClick={() => {download("https://meta.cratch.io/static/media/setup.zip")}} _focus={{border : "1px solid grey"}} _active={{
                    border : "1px solid grey"
                  }} rightIcon={<HiOutlineDownload size="1.4rem" color="black" />} mt={5} bg="#fcfcfc" color="black" padding={6} fontSize={["1.1rem","1.2rem"]} textAlign="center" _hover={{
                    bg : "rgb(239, 241, 242,0.9)",
                    color: "black"
                  }}>Download</Button>

                  </Center>
                 
                </Box>
             </Center>   
         </ModalBody>
        </ModalContent>
      </Modal>

      </Box>
    
  
    </>
  );
}

export default App;
